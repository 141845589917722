import { AuthConfig } from 'angular-oauth2-oidc';
//
// export const authConfig: AuthConfig = {
//   issuer: 'https://demo.identityserver.io',
//   clientId: 'interactive.public', // The "Auth Code + PKCE" client
//   responseType: 'code',
//   redirectUri: window.location.origin + '/index.html',
//   silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
//   scope: 'openid profile email api offline_access', // Ask offline_access to support refresh token refreshes
//   useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
//   silentRefreshTimeout: 5000, // For faster testing
//   timeoutFactor: 0.25, // For faster testing
//   sessionChecksEnabled: true,
//   showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
//   clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
//   nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding
// };
export const uatAuthConfig: AuthConfig = {
  // issuer: 'https://dev1-member-portal.cs114.force.com/memberportal',
  issuer: 'https://hkairportloyalty--sit.sandbox.my.site.com/hkairportrewards',
  // clientId: '3MVG9iLRabl2Tf4g3YZuGozEB6BuN4jYluJMv9g2SVgMSck09SdmT8P6vb48QYASw_3FBxNTnJ6JejxZOSN1k',
  // UAT
  postLogoutRedirectUri: window.location.origin + '/court/5726866849660928',
  clientId: '3MVG9iLRabl2Tf4g3YZuGozEB6NeEnNMmxoecxzvB6fThzBtBlEtw7GYsuuuAC6tJp6OeWCJ9q2dc0WxCIy6b',
  dummyClientSecret : '0A75F1F6A7A7A1A1199F48623CABF9906D4221FBA9847AA1687D557EA92F9313',
  redirectUri: window.location.origin + '/court/5726866849660928',
  silentRefreshShowIFrame: false,
  scope: 'openid',
  useSilentRefresh: false,
  sessionChecksEnabled: true,
  strictDiscoveryDocumentValidation: false,
  // TODO:this url is not registered yet
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  skipIssuerCheck: true,
  showDebugInformation: true,
  silentRefreshTimeout: 30000

};
//
// DEV > Aigens Internal
// Consumer Key: 3MVG9iLRabl2Tf4g3YZuGozEB6NeEnNMmxoecxzvB6fThzBtBlEtw7GYsuuuAC6tJp6OeWCJ9q2dc0WxCIy6b
// Consumer Secret: 430B920E4C3FD983B323CD3DEB2DC264BB916941EC622A9E4C2AE769876FDF06
// Login URL: https://dev1-member-portal.cs114.force.com/memberportal/s/login/?language=en_US
//     SSO authorize endpoint: https://dev1-member-portal.cs114.force.com/memberportal/services/oauth2/authorize
//
//     SIT > Aigens UAT
// Consumer Key: 3MVG9Nvmjd9lcjRlR2OVFJuq2rjAosD0_8wIc2gO2LZM7K9Gvpw2afQjsMcM0VngvZUmCjYxPcC61TiYkyaPD
// Consumer Secret: 0A75F1F6A7A7A1A1199F48623CABF9906D4221FBA9847AA1687D557EA92F9313
// Login URL: https://hkairportloyalty--sit.sandbox.my.site.com/hkairportrewards/s/login/?language=en_US
//     SSO authorize endpoint: https://hkairportloyalty--sit.sandbox.my.site.com/hkairportrewards/services/oauth2/authorize
//
//     Corrected domain by Winnic yesterday (DEV) @⁨Mike⁩ take your time thanks
