export function ZH_JSON(): any {
  return zh;
}
const zh = {
  'buttons': {
    'cancel': '取消',
    'confirm': '確認',
    'continue': '繼續',
    'lang-cancel': 'Cancel',
    'lang-ok': 'OK',
    'language': '語言',
    'no': '否',
    'ok': '確定',
    'yes': '是'
  },
  'dialog': {
    'select-table': {
      'invalid-input': '輸入錯誤'
    }
  },
  'drinks': {
    'cold-drink': '凍飲',
    'hot-drink': '熱飲',
    'soup': '湯',
    'special-drink': '特飲'
  },
  'global': {
    'HK': '香港',
    'add-toast-msg': '已加入訂單',
    'add-reward-msg': '已加入獎賞',
    'additional-msg-hkday': '請於完成付款後的{{time}}分鐘內到{{store}}({{branch}}分店)領取',
    'back': '返回',
    'bk-pos-error-message': '此郵編不支持配送服務',
    'breakfast-close': '早市手機訂餐服務暫停，請稍後再試。',
    'checkout': '查看購物車',
    'checkout-pay-later': '確定',
    'dinner-close': '晚市手機訂餐服務暫停。',
    'done': '完成',
    'logout-remind': '登出會清空你的訂單，您確定嗎？',
    'eat-now': '即時',
    'female': '女性',
    'home': '首頁',
    'item-added': '已加入訂單',
    'items': '項',
    'kiosk-offline-message': '手機訂餐服務暫時停止，請稍後再試。',
    'lang': '繁體中文',
    'lunch-close': '午市手機訂餐服務暫停，請稍後再試。',
    'male': '男性',
    'max-delivery-message': '外送最高消費是',
    'max-delivery-message-bk': '你的訂單金額超過$100，請發郵件至 atyourservice@burgerking.com.sg 作進一步安排',
    'max-item-message': '所選食品數量已達上限',
    'min-delivery-message': '外送最低消費是',
    'min-delivery-message-bk': '訂單起送價格為$15。請繼續點餐。',
    'minutes': '分鐘',
    'minutes-later': '分鐘後',
    'next': '下一步',
    'next-page': '下一頁',
    'no-menu': '這時段沒有食品提供',
    'no-of-items': '款食品',
    'no-service-message': '手機訂餐服務暫停。',
    'no-store-message': '暫時未有分店營業',
    'noEnoughPoints': '積分不足',
    'order-first': '請先選擇食品',
    'order-warning-msg': '請先選擇食品',
    'other': '不提供',
    'payment-method': '選擇付款方式',
    'pick-up-charge': '外賣收費',
    'pick-up-time-invalid': '提取時間已過。請重選提取時間。',
    'please-select': '請選擇',
    'please-select-alert-title': '選擇提取時間',
    'please-select-max': '最多選擇',
    'powered-by': 'Powered by Aigens',
    'previous': '上一頁',
    'quantity': '數量',
    'total-quantity': '總數量',
    'ready-in': '制作時間',
    'select': '選擇',
    'step1': '第',
    'step2': '步',
    'store-closed-message': '已關店。',
    'table-warning-msg': '請輸入正確枱號',
    'tea-time-close': '茶市手機訂餐服務暫停，請稍後再試。',
    'to-start': '開始點餐',
    'up': '起',
    'wrong-email-message': '請輸入正確電郵地址',
    'wrong-phone-message': '請輸入正確手機號碼',
    'wrong-first-name-message': '請輸入名字',
    'wrong-last-name-message': '請輸入姓氏',
    'wrong-time': '網站已逾時，請重新載入網頁。 (或手機時間不正確，請重新設定時間)',
    'mr': '先生',
    'mrs': '太太',
    'ms': '女士',
    'miss': '小姐',
    'customerName': '名稱',
    'cardType': '卡類型',
    'food-time-out': '所選的食物已超時,請重新挑選',
    'timeZone-error': '您的手機時區設定並非香港標準時間(GMT+8)，請重新設定後再提交訂單。',
    'select-restaurant': '選擇餐廳',
    'hkaaTitle': '香港國際機場'
  },
  'pages': {
    'coupon-list': {
      'select-coupon': '選擇現金券',
      'coupon-reminder': '使用現金券即表示您已閲讀並同意現金券的條款和細則',
      'no-coupon-reminder': '您沒有任何現金券。',
      'tnc': '條款和細則',
      'qty': '總數量:',
      'valid-until': '有效期至:',
      'coupon': '現金券'
    },
    'base': {
      'missing-tnc': '請加入使用條款',
      'order-time-out': '訂單逾時',
      'time-out-message': '所選食品將被移除'
    },
    'openrice': {
      'title': '稱謂',
      'CCCode': '國家區號',
      'visa': 'VISA',
      'master': '萬事達',
      'amex': '美國運通',
      'union': '銀聯',
      'out-of-stock': '食品已售罄',
      'marketing-explain': '立即訂閱最新資料，我們會第一時間為您送上獨家的推廣及優惠!',
      'marketing': '我希望收到香港國際機場的優惠券､推廣資訊及最新消息，並同意機場管理局使用我的個人資料作此用途。',
      'no-credit-card': '請選擇卡類型'
    },
    'page-byod-brand-splash': {
      'faq': '常見問題'
    },
    'profileModal': {
      'member-profile': '會員詳情',
      'member-number': '會員號碼',
      'name': '姓名',
      'mobile': '手機號碼',
      'email': '電郵地址',
      'language': '語言',
      'birth': '生日',
      'area': '居住地區',
      'preferred-communication-language': '偏好語言',
      'company': '公司名稱',
      'companySubTitle': '機場員工資料',
      'referral-code': '推薦碼',
      'certificate-number': '機場禁區通行證／機組成員證號碼',
      'expiry-date': '到期日',
      'staff-tatus': '機場員工狀態',
      'approved': '成功登記',
      'toBeExpired': '已過期 (請盡快更新)',
      'pending': '正在審核',
      'declined': '登記失敗',
      'expired': '已過期'
    },
    'brand-store-list': {
      'alert-title': '所選食品將被移除，\n你確定嗎?',
      'close-message': '暫停營業',
      'dinein-only': '只限堂食',
      'opening-hours': '營業時間',
      'takeaway-only': '只限外賣',
      'alert-title-hk': '離開點餐？',
      'alert-subtitle-hk': '所選食品將被移除，\n你確定嗎?',
      'alert-nobtn-hk': '取消',
      'alert-removebtn-hk': '移除'
    },
    'byod-summary': {
      'discount': '折扣',
      'no': '不了',
      'orderFailure': '請與我們職員聯絡',
      'paidWGpay': '使用Google Pay付款',
      'payHere': '前往付款',
      'printer-disconnect-message': '打印機離線',
      'qrcode-message-one': '請往相關櫃檯掃瞄以下取餐QR Code。',
      'qrcode-message-two': '請於訂單付款成功後的15分鐘內領取您的食物, 逾期作廢並不作退款',
      'reverse-order-button': '倒序訂單',
      'service-charge': 'Prices are subject to 10% service charge and prevailing GST.',
      'subtitle': '訂單',
      'survey1': '你好👋, 用餐愉快嗎?',
      'survey2': '告訴我們你的體驗, 好讓我們下次為你提供更好的服務',
      'table': '枱',
      'tables': '枱號',
      'title': '訂單紀錄',
      'yes': '好'
    },
    'cart': {
      'member-send-order': ' 使用會員身份結賬以獲取積分或優惠',
      'guest-send-order': '確定以訪客身分結帳',
      'add': '加單',
      'apply': '使用',
      'changeReward': '更改',
      'confirm': '訂單一經確定，恕不能更改或取消。',
      'continue-order': '繼續點餐',
      'delete': '刪除',
      'delivery-message': '製作時間大約 ',
      'delivery-time': '送貨時間',
      'discount-code-fail': '折扣碼無效',
      'discount-code-ok': '成功使用折扣碼',
      'done': '完成',
      'edit': '修改',
      'edit-cap': '修改',
      'input-discount-code': '輸入折扣碼',
      'last-update-time': '最後更新時間',
      'miyo': 'Have it your way',
      'myReward': '我的獎賞!',
      'order-more': '繼續點餐',
      'ordered-time': '下單時間 : ',
      'payment-no-supported': '你當前的瀏覽器不支持Payment, 請更換 Safari / Chrome',
      'pickUp-time': '取餐時間',
      'please-select': '請選擇取餐時間',
      'save-order': '儲存訂單',
      'selected-1': '已選 ',
      'selected-2': ' 項',
      'selectedReward': '已選用以下獎賞',
      'selectReward': '有可用獎賞！',
      'send-order': '確定結帳',
      'send-order-pay-later': '確定送出訂單',
      'special-request': '特別要求',
      'subtotal': '小計',
      'tax-included': ' (含稅)',
      'time-limit-1': '我們正努力準備你的訂單, 請於',
      'time-limit-2': '後再試',
      'title': '訂單資料',
      'total': '總計',
      'up-selling-message': '你或許有興趣...',
      'use-reward': 'Hey!想今天就使用優惠嗎？',
      'useReard': '使用獎賞!',
      'wechat-missing-openid': '缺少OpenID, 請用微信掃描二維碼重新進入',
      'wechat-no-supported': '想用微信支付? 請用微信掃描二維碼重新進入',
      'your-order': '已點選食品',
      'pickup-now': '現在',
      'no-payment-error': '系統錯誤, 找不到付款方法, 請聯絡櫃台',
      'process-to-payment': '選擇付款方式'
    },
    'credit-card-payment': {
      'credit-card': '信用卡資料',
      'credit-card-info': '信用卡資料:',
      'credit-card-number': '信用卡號碼',
      'cvv': '安全碼',
      'month': '月份',
      'receiver-info': '持卡人資料',
      'year': '年份'
    },
    'app-DineinOrTakeawayPage': {
      'nextDay': '翌日',
      'multi-orders-suggestion': '您可同時選擇多間餐廳美食'
    },
    'freeGift': {
      'checkNewReward': '查看你的獎賞',
      'rewardUnlocked': '已解鎖新的獎賞!'
    },
    'item-grid': {
      'add': '加至訂單',
      'order': '下單',
      'soldout': '售罄'
    },
    'item-select': {
      'chooseAtMost': '請選最多{{max}}項',
      'chooseTo': '請選{{min}} - {{max}}項',
      'chooseUp': ' 請選至少{{min}}項',
      'cold-drink': '凍飲',
      'confirm': '確定',
      'hot-drink': '熱飲',
      'no-need': '不需要',
      'optional': '加配項目',
      'others-item': '其他',
      'select-exact-no-of-items': '* 請選擇{count}項食品',
      'select-items': '請選擇{{count}}項',
      'select-no-of-items': '* 請選擇{min}至{max}項食品',
      'soft-drink': '汽水',
      'soup': '湯',
      'special-drink': '特飲'
    },
    'login': {
      'createAcct': '創建帳號',
      'email': '電郵',
      'incomplete-profile': '請完成資料',
      'label-title-1': '使用您的',
      'label-title-2': '登錄',
      'login': '登入',
      'memberId': '會員號碼',
      'no-account': '沒有帳戶?',
      'password': '密碼',
      'passwordEmpty': '請輸入你的密碼',
      'phone': '手機號碼',
      'register': '註冊',
      'reset-password': '忘記密碼',
      'social-media-title': '社交媒體登錄',
      'storellet-title': '使用您的手機號碼登錄',
      'title': '使用您的電郵/手機號碼登錄',
      'username': '帳號',
      'username-placeholder': '電郵/手機號碼(連區號)',
      'usernameEmpty': '請輸入你的帳號',
      'reset-password-success': '重設密碼成功'

    },
    'member-reward': {
      'apply-coupon': '在付款處使用優惠券',
      'contunueOrdering': '繼續點餐',
      'how-to-use': '如何使用',
      'logged': '你已成功登入',
      'points-accu': '消費積分',
      'profile': '用户資料',
      'recent-promotion': '最新推廣',
      'redeem': '立刻使用',
      'reward-available': '可用獎賞',
      'valid-from': '有效期',
      'empty-mem': '即將有更多優惠',
      'stamps': '會員印花',
      'stamp-rule': '每惠顧一碗拉麵將獲得一個印花'
    },
    'menu-list': {
      'menu-fab': 'menu-zh1',
      'title': '餐單管理'
    },
    'modifier-item-select': {
      'require': '必選',
      'title': '特別要求',
      'modify': '特別要求'
    },
    'multi-order-history': {
      'refresh': '更新...'
    },
    'multi-order-cart-page': {
      'order-countdown': '訂單將在 {{minutes}}分鐘{{seconds}}秒 内到期'
    },
    'order-status': {
      'fail-message-one': '落單失敗',
      'fail-message-two': '請聯絡我們的店員',
      'fail-notification': '訂單無法傳送，交易已取消',
      'sending-to-pos-notification': '訂單傳送中',
      'success-message-one': '成功下單',
      'success-message-three': '當訂單已準備完成，請前往相關餐廳取餐。',
      'success-message-two': '您可在「我的訂單」查看訂單狀態',
      'call': '您也可以致電顧客服務熱線 ',
      'call-message': ' 查詢訂單狀態 (服務時間：09:00 to 01:00)'
    },
    'order-status-byod': {
      'back': '查看訂單紀錄',
      'back-to-home': '返回首頁',
      'message-two': '請通知店員協助掃描QR Code付款',
      'pending-1': '抱歉,您的訂單暫時未能傳送!',
      'pending-2': '您可以在',
      'pending-3': '訂單頁面',
      'pending-4': '查看您的訂單',
      'received-1': '您的訂單已成功傳送!',
      'received-2': '您可以在',
      'received-3': '訂單頁面',
      'received-4': '查看您的訂單',
      'sending-1': '請稍候!',
      'sending-2': '已成功提交訂單',
      'sending-3': '正等候回應中',
      'summary': '訂單',
      'title': '訂單狀態'
    },
    'order-status-prekiosk': {
      'alert-message-one': '請於',
      'alert-message-two': '前往所選定分店的自助售票機付款系統及取單。若逾時未取，請閣下重新輸入訂單，謝謝！',
      'alert-title': '成功訂餐!',
      'back': '返回首頁',
      'expire-time': 'Expire Time',
      'items': ' item(s)',
      'message-four': '前到自助機前掃瞄QR碼',
      'message-one': '您的訂單已成功傳送',
      'message-three': '請在',
      'message-two': '請於指定自助機前掃瞄QR碼付款',
      'method': 'Method',
      'order-detail': '訂單資料',
      'order-grand-total': '總計',
      'store': 'Place',
      'store-address': '地址'
    },
    'payment': {
      'alipay-check-out': '前往支付寶',
      'card-number': '信用卡號碼',
      'check-out': '結賬',
      'email': '電郵',
      'email-optional': '電郵 (選填)',
      'email-required': '電郵 (必填)',
      'expires': '到期日',
      'message-one': '完成付款後，您將收到一封確認電郵',
      'message-two': '你亦可透過短訊中鏈結查看訂單狀況',
      'mpgs-error': '信用卡號碼無效',
      'my-card': '我的信用卡',
      'phone': '電話號碼',
      'phone-optional': '電話號碼 (選填)',
      'phone-required': '電話號碼 (必填)',
      'please-enter-email': '請輸入電郵',
      'security-message-1': '以上安全加密付款服務由',
      'security-message-2': '提供',
      'special-message': '此網上商戶在香港特別行政區運作',
      'title': '付款',
      'wechat-check-out': '前往微信支付',
      'wechat-pay-total': '支付金額',
      'no-refund-after-payment': '付款後恕不退款',
      'alipay-wechat-reminder': '使用支付寶 / 微信支付必須先完成實名認證及開通境外支付'
    },
    'pre-order': {
      'item-saved': '以下項目已成功加入至已存訂單',
      'order-saved': '訂單已存',
      'page1': './assets/images/preOrder/preIns1_zh.jpg',
      'page2': './assets/images/preOrder/preIns2_zh.jpg',
      'saved-order-found': '找到已存訂單',
      'saved-order-msg': '已成功將訂單導入, 點擊此處查看',
      'seated-action': '已入座? 按此輸入驗證碼',
      'go-cart': '訂單總結'
    },
    'profile': {
      'alert-title': '你確定要登出嗎？',
      'complete-profile': '請完成你的簡介',
      'date-of-birth': '出生日期',
      'edit': '編輯',
      'email': '電子郵件',
      'first-name': '名字',
      'gender': '性別',
      'last-name': '姓氏',
      'log-out': '登出',
      'phone': '電話',
      'profile': '個人資料',
      'profile-updated': '個人資料已更新',
      'reset-password': '重設密碼',
      'reset-password-success': '重設密碼成功',
      'save': '儲存'
    },
    'redirect': {
      'open-wechat': '開啟微信',
      'use-wechat': '請使用微信應用程式開啟連結'
    },
    'registration': {
      'alert-title': '你確定要離開嗎？',
      'confirm-password': '確認密碼',
      'create-message': '創建帳號',
      'DOB': '出生日期',
      'gender': '性別',
      'email': '電郵',
      'message': '',
      'name': '姓名',
      'first-name': '名字',
      'last-name': '姓氏',
      'password': '密碼',
      'password-rule': '建議8個字符以上及一個英文字母',
      'phone': '手機號碼',
      'title': '註冊',
      'required': '必填'
    },
    'reset-password': {
      'confirm-password': '確認密碼',
      'count-down-message-one': '在',
      'count-down-message-two': '秒後可以重新傳送驗證碼',
      'email': '電郵',
      'email-receive-code-message': '請輸入電郵地址用以接收重置密碼驗證',
      'input-code-message-one': '請輸入從',
      'input-code-message-two': '接收的4位數字驗證碼',
      'old-password': '舊密碼',
      'password': '密碼',
      'phone': '手機',
      'phone-receive-code-message': '請輸入手機號碼用以接收重置密碼短訊',
      're-send-code-message': '重新傳送驗證碼',
      'receive-email-message': '請輸入電郵地址以接收重置密碼驗證',
      'receive-message': '請輸入電郵地址或手機號碼以接收重置密碼驗證',
      'reset-password-message': '設置新密碼',
      'submit': '提交',
      'no-payment-error': '系统错误，找不到付款方法，请联系柜台'

    },
    'reward-list': {
      'conditions': '條件',
      'my-coupons': '我的優惠券',
      'terms': '條款'
    },
    'saved-order-list': {
      'direction-text': '入座後請選擇其中一種方式以完成點餐',
      'input-code': '輸入驗證碼',
      'scan-code': '掃瞄QR Code'
    },
    'scan-qrcode': {
      'error-message-default': '無法讀取, 請重新掃瞄',
      'error-message-nocamera': '無法開啟掃瞄功能',
      'error-message-notcompatible': '此瀏覽器不支援掃瞄功能, 請手動輸入檯號',
      'error-message-timeout': '掃瞄時間逾時!',
      'loading-message-reading': '讀取檯號中',
      'loading-message-scanning': '掃瞄中...',
      'loading-message-starting': '開啟鏡頭中'
    },
    'search-item': {
      'recent-search': '最近搜尋',
      'search': '搜尋食品',
      'show': '顯示',
      'results': '項結果',
      'search-result': '搜尋結果'
    },
    'select-table': {
      'please-select': '請選擇',
      'room': '房間',
      'select-location': '選擇位置',
      'table': '檯號',
      'table-number': '檯號'
    },
    'set-select': {
      'click-to-start': '按此處開始點餐吧',
      'confirm-order': '加入訂單',
      'edit-order': '確定修改',
      'mandatory': '請選擇',
      'miyo': 'Have it your way',
      'no-need': '不需要',
      'not-yet-completed': '尚未揀好',
      'optional': '可選',
      'picked': '已選',
      'please-select': '請選擇',
      'select-error': '請點選所有選項',
      'serve-with': '跟餐',
      'special-request': '特別要求',
      'complete-first': '先揀好跟餐食物'
    },
    'splash': {
      'welcome': '歡迎',
      'address': '店鋪位置',
      'continue': '繼續加單',
      'reorder': '您有未完成的訂單，要繼續下單嗎？',
      'continue-ask': '訂單已被提交。需要加單?',
      'date': '- 日期 -',
      'dine-in': '堂食',
      'enterPasscode': '請輸入通行碼',
      'enterPasscode-hkbh': '你可以在手環上找到編碼',
      'enterPasscode-hkbh1': '請如圖示, ',
      'enterPasscode-hkbh2': '輸入你手帶上的編碼(連同引號)',
      'enterPasscode-hkbh3': '例如A301-1 或 A101',
      'errorPasscode': '編碼格式錯誤, 請再輸入',
      'general-table-locked': '點餐時間已過',
      'login': '登入',
      'member': '會員',
      'mobile-only': '只接受手機下單',
      'prompt-dine-mode': '請選擇',
      'session': '- 交易號碼 -',
      'staff': '- 員工 -',
      'start': '點擊開始落單',
      'store-close': '店鋪已關閉',
      'input-seats': '輸入用餐人數',
      'input-seats-subtitle': '如之後需更改人數,請聯絡職員',
      'summary': '我的訂單',
      'seats-placeholder': '1-20',
      'table': '枱號',
      'table-locked': '枱號被另一部手機鎖定',
      'takeout': '外賣',
      'no-cat-error': '點餐服務暫時停止，請稍後再試。',
      'food-order-service': '食物訂購服務'
    },
    'summary-list': {
      'current-order': '現時訂單',
      'detail': '詳細資料',
      'lines1': '現在取餐？',
      'lines2': '滑動確認取餐前請向店員出示此頁面，否則當已取餐論。',
      'lines3': '*請於30分鐘內取餐，逾時訂單作廢。',
      'lines4': '取餐成功',
      'lines5': '多謝惠顧!',
      'lines6': '是次消費所賺取的積分將於下一個工作天內存入你的 LP Club 帳戶',
      'order-no': '訂單號碼',
      'past-order': '過去訂單',
      'pick-up-now': '按此取餐',
      'show-more': '更多',
      'completed-order': '已完成訂單',
      'pick-up-no': '取餐編號',
      'customer-hotline': '請致電服務熱綫查詢訂單狀態',
      'no-order': '您尚未提交任何訂單',
      'order-cancelled': '訂單取消'

    },
    'multi-order-detail': {
      'scan-hint': '請前往指定餐廳領取您的訂單。如果您未在指定時間領取訂單，訂單將被取消且不予退款。',
      'ref-nubmer': 'POS 參考號碼',
      'dining-method': '用餐模式'
    },
    'terms-conditions': {
      'agree-text': '我同意接收來自{{name}}的推廣和市場信息',
      'cancel': '取消',
      'confirm': '本人同意並接受條款及細則及私隱政策',
      'navbarTitle': '使用條款',
      'offlineHint': '請連接網絡然後重試',
      'receive-brand': '我同意接收{{name}}的優惠及推廣訊息',
      'receive-brandPartner': '我同意接收{{name}}的商業夥伴的優惠及推廣訊息',
      'receive-order-place': '我同意接收Order.place的優惠及推廣訊息',
      'Reload': '重新載入'
    },
    'variation': {
      'confirm': '確認',
      'title': '請選擇'
    },
    'verify-registration': {
      'complete': '完成',
      'count-down-message-one': '在',
      'count-down-message-two': '秒後可以重新傳送驗證碼',
      'input-code-message-one': '請輸入從',
      'input-code-message-two': '接收的6位數字驗證碼',
      'invalid-email': '請輸入正確的電郵地址',
      'invalid-phone': '重新傳送驗證碼',
      'phone-receive-code-message': '請輸入手機號碼以接收6位數字的驗證碼短訊',
      're-send-code-message': '重新傳送驗證碼',
      'redirect-message': '進入註冊頁面',
      'submit': '提交',
      'verification-message': '驗証成功'
    },
    'verify-table-code': {
      'confirm-button': '確定',
      'enter-message': '輸入枱紙上的驗證碼',
      'enter-message-sub': '我們會將你已儲存的餐點放入訂單',
      'wrong-code-message': '號碼錯誤，請重試'
    },
    'virtual-scroll-category-list': {
      'cart': '購物車'
    }
  },
  'payments': {
    'alipay': 'Alipay',
    'alipayCN': '支付寶(內地)',
    'alipayHK': '支付寶(香港)',
    'androidpay': 'Google Pay',
    'applepay': 'Apple Pay',
    'cash': '現金',
    'creditcard': '信用卡',
    'noGooglePay': 'Google Pay 暫時不可用。請選擇其它付款方式。',
    'oepay': '八達通',
    'wechat': '微信支付'
  },
  'status': {
    'byod': {
      'cancelled': '已取消',
      'completed': '完成',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '製作中',
      'refunded': '已退款',
      'rejected': '發送失敗',
      'sending': '發送中'
    },
    'delivery': {
      'cancelled': '已取消',
      'completed': '完成',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '製作中',
      'refunded': '已退款',
      'rejected': '發送失敗',
      'sending': '發送中'
    },
    'dinein': {
      'cancelled': '已取消',
      'completed': '完成',
      'failed': '失敗',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '製作中',
      'refunded': '已退款',
      'rejected': '發送失敗',
      'sending': '發送中'
    },
    'kiosk': {
      'cancelled': '已取消',
      'completed': '完成',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '製作中',
      'refunded': '已退款',
      'rejected': '發送失敗',
      'sending': '發送中'
    },
    'prekiosk': {
      'cancelled': '已取消',
      'completed': '完成',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '製作中',
      'refunded': '已退款',
      'rejected': '發送失敗',
      'sending': '發送中'
    },
    'takeaway': {
      'cancelled': '已取消',
      'completed': '完成',
      'failed': '失敗',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '製作中',
      'refunded': '已退款',
      'rejected': '發送失敗',
      'sending': '發送中'
    },
    'hkaa': {
      'processing': '處理中'
    }
  },
  'tags': {
    'preorder': '預購',
    'queue': '排隊',
    'reservation': '訂座'
  },
  'types': {
    'Brand': '品牌',
    'ErrorMessage': '錯誤信息',
    'File': '文件',
    'Group': '集團',
    'Member': '會員',
    'Store': '商店'
  }
};
